@use "variables" as variable;
@use "mixins" as mixin;

.datepicker {
  .react-datepicker-wrapper {
    display: block;
    max-width: 245px;
    margin: auto;
    display: block;
  }
  .react-datepicker-popper {
    z-index: 9;
    .react-datepicker__triangle::after {
      border-bottom-color: variable.$primaryColor !important;
    }
    .react-datepicker__navigation-icon {
      left: 0;
      top: 0;
    }
    .react-datepicker__day--selected {
      background-color: variable.$buttonColorHover !important;
      color: #fff;
    }
    button {
      border-radius: 50%;
      margin: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .react-datepicker__header {
      background-color: variable.$primaryColor;
      color: #fff;

      &__dropdown {
        padding: 8px 15px;
        margin: 13px 15px 6px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        gap: 10px;
        border-radius: 4px;

        > div {
          flex: 1;
        }
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name,
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: #fff;
      }
      .react-datepicker__year-dropdown,
      .react-datepicker__month-dropdown,
      .react-datepicker__month-year-dropdown {
        background-color: variable.$primaryColor;
      }
      .react-datepicker__year-option:hover,
      .react-datepicker__month-option:hover,
      .react-datepicker__month-year-option:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      .react-datepicker__navigation--years-upcoming,
      .react-datepicker__navigation--years-previous {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 15px;
          height: 15px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(-45deg) translate(-50%, -50%);
          top: 50%;
          left: 50%;
          margin-left: 3px;
        }
      }
      .react-datepicker__navigation--years-previous {
        &:after {
          transform: rotate(45deg) translate(-50%, -50%) scaleY(-1);
          margin-left: -7px;
          margin-top: -5px;
        }
      }
    }
  }
}
