@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  label {
    position: relative;
    display: block;
    width: 50px;
    height: 25px;
    margin: 0 10px;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .customInput {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: variable.$GreyColor;
    transition: 0.3s;
    border-radius: 30px;
  }
  strong {
    font-family: variable.$primaryFont;
    color: variable.$primaryColor;
    font-size: variable.$mediumSize;
    cursor: pointer;
  }
  .customInput:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }
  input:checked + .customInput {
    background-color: variable.$fadePrimaryColor;
  }
  input:checked + .customInput:before {
    transform: translateX(24px);
  }
  &.disabled {
    &::before {
      content: "";
      position: absolute;
      z-index: 3;
      @include mixin.coverAll;
      cursor: not-allowed;
    }
    .customInput img {
      position: absolute;
      right: 6px;
      top: 6px;
      width: 13px;
      height: 13px;
    }
    &.off {
      .customInput img {
        left: 6px;
        right: auto;
      }
    }
  }
}

@include mixin.mediaSmallTabletSize() {
  .root {
    label {
      width: 60px;
      height: 30px;
    }
    .customInput:before {
      height: 25px;
      width: 25px;
    }
    input:checked + .customInput:before {
      transform: translateX(29px);
    }
    &.disabled {
      .customInput img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
