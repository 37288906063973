@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.panel {
  padding: 0;
  background-color: #fff;
  margin-bottom: 15px;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(90, 0, 140, 0.47);
  .panelContent {
    padding: 10px 50px 20px 15px;
    position: relative;
    cursor: pointer;
  }
  .panelHeader {
    padding: 8px 15px;
    background-color: variable.$bgColor;
    border-bottom: 1px solid variable.$primaryColor;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: variable.$size20;
      font-family: variable.$titlesFont;
      color: variable.$primaryColor;
    }
    &.colored {
      background-color: variable.$primaryColor;
      .title {
        color: variable.$whiteColor;
      }
    }
  }
}
@include mixin.mediaTabletSize {
  .panel {
    .panelHeader {
      justify-content: inherit;
    }
  }
}
