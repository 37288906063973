@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.account {
  display: none;
}

@include mixin.mediaSmallTabletSize() {
  .account {
    display: block;
    margin: 0 7px;
    position: relative;
    a {
      color: variable.$blackColor;
      font-family: variable.$primaryFontSemiBold;
      font-size: 12.5px;
      line-height: 24px;
      padding-left: 24px;
      position: relative;
      display: block;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: auto;
      }
    }
    &:hover {
      .submenu {
        display: block;
      }
    }
    .submenu {
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      padding-top: 18px;
      z-index: 100;
      display: none;
      li {
        background-color: #fff;
        border-radius: 0 0 10px 10px;
        border: 2px solid variable.$primaryColor;
        a {
          white-space: nowrap;
          color: variable.$primaryColor;
          padding: 10px 15px;
          font-size: variable.$size14;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
