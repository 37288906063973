@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.stepsContainer {
  width: 20%;
  margin: 0 auto;
  padding-top: 0;
  .menuProg {
    position: relative;
    .boxMenu {
      display: flex;
      min-height: 12px;
      .stepBox {
        font-family: variable.$primaryFont;
        width: 50%;
        text-align: center;
        padding-top: 12px;
        position: relative;
        color: #ad80c5;
        font-size: 12px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          height: 3px;
          width: 100%;
          background-color: #8f8f8f;
          z-index: 1;
        }
        &::after {
          content: attr(data-step);
          display: block;
          position: absolute;
          left: 0;
          top: -8px;
          background-color: #ad80c5;
          width: 20px;
          height: 20px;
          border-radius: 25px;
          z-index: 2;
          font-family: variable.$titlesFont;
          color: variable.$primaryColor;
          line-height: 20px;
          font-size: variable.$size15;
        }
        a {
          color: variable.$primaryColor;
          display: block;
        }

        &.prevStep {
          &::before,
          &::after {
            background-color: variable.$primaryColor;
            color: variable.$whiteColor;
          }
          label {
            color: variable.$primaryColor;
          }
          a {
            color: variable.$primaryColor;
          }
        }
        &.activeStep {
          &::after {
            background-color: variable.$primaryColor;
            color: variable.$whiteColor;
          }
          label {
            color: variable.$primaryColor;
          }
        }
        &:last-child {
          width: 0;
        }
        label {
          font-family: variable.$titlesFont;
          font-size: variable.$size13;
          width: 84px;
          display: block;
          position: absolute;
          left: -30px;
          top: -8px;
          z-index: 3;
          letter-spacing: 0.3px;
          span,
          a {
            display: block;
            padding-top: 23px;
          }
        }
      }
    }
  }
}

@include mixin.mediaTablet() {
  .stepsContainer {
    width: 60%;
    order: 4;
    padding-top: 20px;
    margin-bottom: 20px;
    .menuProg {
      margin: 0 auto;
    }
  }
}
