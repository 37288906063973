@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.header {
  background-color: variable.$whiteColor;
  padding: 0;
  position: relative;
  z-index: 100;
  box-shadow: 0 0px 5px -1px #ccc;
  .wrap {
    position: static;
    > div {
      align-items: center;
      overflow: hidden;
    }
  }
}

@include mixin.mediaDesktopSize() {
  .header {
    padding: 0 10px;
    .wrap {
      max-width: 1020px;
      overflow: visible;
      > div {
        align-items: center;
        overflow: visible;
        gap: 13px;
      }
    }
  }
}
