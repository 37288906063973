@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  position: relative;
  max-width: max-content;
  inline-size: max-content;
  &:hover,
  &.alwaysVisible {
    .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
  .tooltip {
    position: absolute;
    background-color: currentColor;
    padding: 3px;
    border-radius: 5px;
    pointer-events: none;
    min-height: 45px;
    display: flex;
    align-items: center;
    min-width: max-content;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 4px 5px rgba($color: variable.$primaryColor, $alpha: 0.2);
    border: 1px solid rgb(55 55 55 / 15%);
    &:after {
      content: "";
      position: absolute;
      color: inherit;
      border: 8px solid transparent;
    }
    &.top {
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      &::after {
        border-top-color: currentColor;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
      }
    }
    &.bottom {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      &::after {
        border-bottom-color: currentColor;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
      }
    }
    &.left {
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      &::after {
        border-left-color: currentColor;

        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.right {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      &::after {
        border-right-color: currentColor;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    > span {
      color: variable.$whiteColor;
      width: 100%;
    }
  }
}

@include mixin.mediaTabletSize() {
  .wrapper {
    .tooltip {
      padding: 3px 8px;
    }
  }
}
