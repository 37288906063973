@font-face {
  font-family: "SFProDisplay";
  src: url("/static/fonts/SFProDisplay-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay-Bold";
  src: url("/static/fonts/SFProDisplay-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay-Heavy";
  src: url("/static/fonts/SFProDisplay-Heavy.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SFProDisplay-Black";
  src: url("/static/fonts/SFProDisplay-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PassionOne";
  src: url("/static/fonts/PassionOne-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gellatio";
  src: url("/static/fonts/Gellatio.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/static/fonts/SFProDisplay-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Caveat Brush";
  src:
    url("/static/fonts/CaveatBrush-Regular.woff2") format("woff2"),
    url("/static/fonts/CaveatBrush-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Freeman";
  src:
    url("/static/fonts/Freeman-Regular.woff2") format("woff2"),
    url("/static/fonts/Freeman-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
