@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.head {
  padding: 21vw 0;
  position: relative;
  background-color: #dbafea;
  .title {
    max-width: 65vw;
    margin: auto;
    img {
      width: 30vw;
    }
  }
  > img {
    display: none;
  }
  > div {
    position: relative;
    z-index: 10;
  }
}
@include mixin.mediaTabletSize() {
  .head {
    padding: 7vw 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%;
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 60% 100%;
      background-image: url("/static/images/bg/bg-methode-left.png");
      z-index: 1;
    }
    &::after {
      left: auto;
      right: 0;
      background-image: url("/static/images/bg/bg-methode-right.png");
      background-position: right top;
    }
    > img {
      display: block;
      position: absolute;
      z-index: 2;
      left: 10px;
      bottom: 0;
      height: 100%;
      width: 270px;
      object-fit: contain;
      object-position: bottom left;
    }
    .title {
      max-width: 300px;
      font-size: 44px;
      img {
        width: 200px;
      }
      &.sm {
        font-size: 30px;
        img {
          width: 120px;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .head {
    padding: 124px 0;
    text-align: center;
    min-height: 410px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 64px;
      max-width: 410px;
      &.sm {
        font-size: 46px;
        img {
          width: 130px;
        }
      }
    }
    > img {
      width: 390px;
    }
  }
}

@include mixin.mediaDesktop() {
  .head {
    > img {
      width: 480px;
    }
  }
}
