.nutriscore {
  position: absolute;
  right: 10px;
  bottom: 38px;
  z-index: 1;
  height: auto;
  img {
    width: 54px;
    height: auto;
  }
}
