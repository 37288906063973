@use "@styles/mixins" as mixin;

.flottant {
  background-color: #000000;
  display: block;
  height: 100% !important;
  left: 0;
  opacity: 0.4;
  position: fixed;
  top: 0;
  width: 100% !important;
  z-index: 991;
}
.root {
  @include mixin.fixedElement;
  background-color: rgb(90 0 140 / 30%);
  -webkit-transition: all 0.3s ease-out-moz;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 99;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  &.flot {
    padding: 62px 0;
    z-index: 999;
    overflow-y: auto;
    scrollbar-width: none;
    .modal {
      position: relative;
      z-index: 995;
    }
  }
  .modal {
    position: relative;
    top: 50%;
    left: 50%;
    min-width: 660px;
    height: auto;
    min-height: 100px;
    z-index: 1;
    @include mixin.centerBox;
    > div {
      background: #ffffff;
      border-radius: 10px;
      overflow: auto;
      max-height: 80vh;
    }
    &:focus {
      outline: none;
    }
    .close {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 39px;
      cursor: pointer;
      text-indent: -9999px;
      border: 0;
      display: block;
      height: 39px;
      background-image: url("/static/images/close-pop.png");
      background-color: transparent;
      background-size: cover;
      z-index: 99;
    }
  }
  .modal-login {
    width: 810px;
  }
}

@include mixin.mediaMobile() {
  .root {
    .modal {
      .close {
        height: 25px;
        width: 25px;
        background-size: cover;
        right: -10px;
        top: -10px;
      }
    }
  }
}
