@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.phone {
  padding: 10px;
  background-color: variable.$primaryColor;
  a {
    display: block;
    font-size: 0;
    line-height: 0;
    text-indent: -99999px;
    background-color: variable.$primaryColor;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    border-radius: 9px;
    overflow: hidden;
  }
  img {
    width: 30px;
    height: auto;
  }
  &.connected {
    display: none;
  }
}

@include mixin.mediaSmallTabletSize() {
  .phone {
    display: none;
  }
}

@include mixin.mediaDesktopSize() {
  .phone {
    display: block;
    margin: 0 0 0 auto;
    a {
      text-indent: 0;
      font-family: variable.$primaryFontBold;
      font-size: variable.$mediumSize;
      line-height: variable.$mediumSize;
      color: variable.$whiteColor;
      position: relative;
      margin-right: 0;
      padding: 5px 5px 5px 50px;
      img {
        width: 30px;
        position: absolute;
        left: 4px;
        top: 6px;
      }
      span {
        font-size: 8px;
        font-family: variable.$primaryFontBold;
        display: block;
        text-align: center;
      }
    }
    &.connected {
      display: block;
    }
  }
}
