@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  @include mixin.buttonPrimary;
  position: relative;
  img {
    position: absolute !important;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -12px !important;
  }
  &:hover {
    background-color: variable.$buttonColorHover;
  }
}
.secondary {
  @include mixin.buttonSecondary;
  position: relative;
}
.default {
  @include mixin.buttonPrimary;
  background-color: variable.$primaryColor;
  font-family: variable.$Freeman;
  position: relative;
  border-radius: 50px;
  box-shadow: none;
  &:hover {
    background-color: rgba($color: variable.$primaryColor, $alpha: 0.7);
  }
  &.outline {
    background-color: transparent;
    border: 1px solid variable.$primaryColor;
    color: variable.$primaryColor;
    &:hover {
      background-color: variable.$primaryColor;
      color: variable.$whiteColor;
    }
  }
}
.white {
  @include mixin.buttonPrimary;
  background-color: variable.$whiteColor;
  color: variable.$primaryColor;
  border-radius: 50px;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    color: variable.$whiteColor;
    border: 1px solid variable.$whiteColor;
  }
  &.outline {
    background-color: transparent;
    border: 1px solid variable.$whiteColor;
    color: variable.$whiteColor;
    &:hover {
      background-color: variable.$whiteColor;
      color: variable.$primaryColor;
    }
  }
}
.rounded {
  border-radius: 50px;
}

.secondaryGreen {
  @include mixin.buttonSecondaryGreen;
  position: relative;
}
.secondaryBlue {
  @include mixin.buttonSecondaryBlue;
  position: relative;
}
