@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes firstIndicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  25% {
    transform: translate(0) scaleX(0.5);
  }
  50% {
    transform: translate(25%) scaleX(0.75);
  }
  75% {
    transform: translate(100%) scaleX(0);
  }
  100% {
    transform: translate(100%) scaleX(0);
  }
}
@keyframes secondIndicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  60% {
    transform: translate(0) scaleX(0);
  }
  80% {
    transform: translate(0) scaleX(0.6);
  }
  100% {
    transform: translate(100%) scaleX(0.1);
  }
}

.iplProgressIndicator {
  background-color: variable.$primaryColor;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 1;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 9999;
  left: 0;
  top: 0;
  &.available {
    opacity: 0;
  }
  .iplProgressIndicatorHead {
    background-color: variable.$primaryColor;
    height: 4px;
    overflow: hidden;
    position: relative;
    .firstIndicator {
      background-color: #ffffff;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-animation: firstIndicator 2s linear infinite;
      -moz-animation: firstIndicator 2s linear infinite;
      animation: firstIndicator 2s linear infinite;
    }
    .secondIndicator {
      background-color: #ffffff;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-animation: secondIndicator 2s linear infinite;
      -moz-animation: secondIndicator 2s linear infinite;
      animation: secondIndicator 2s linear infinite;
    }
  }
  .inspLogoFrame {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-animation: fadein 436ms;
    -moz-animation: fadein 436ms;
    animation: fadein 436ms;
    height: 98%;
    .logo {
      width: 163px;
      height: 38px;
      text-align: center;
      -webkit-align-self: center;
      -moz-align-self: center;
      align-self: center;
      border-radius: 50%;
    }
    .loader {
      text-align: center;
      -webkit-align-self: center;
      -moz-align-self: center;
      align-self: center;
      border-radius: 50%;
    }
  }
}
.transpIndicator {
  background-color: rgba(0, 0, 0, 0.85);
  p {
    color: #ffffff;
    padding: 20px;
  }
}
