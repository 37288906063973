@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.brand {
  padding: 10px 0;
  img {
    max-width: 34vw;
  }
  &.isFunnel {
    display: none;
  }
}
.rightNav {
  &.isFunnel {
    display: none;
  }
}

@include mixin.mediaSmallTabletSize() {
  .brand {
    img {
      max-width: 155px;
    }
  }
  .rightNav {
    display: flex;
    align-items: center;
  }
}
@include mixin.mediaDesktopSize() {
  .brand {
    &.isFunnel {
      display: block;
    }
  }
  .rightNav {
    flex: 1;
    justify-content: space-between;
    max-width: 540px;
    gap: 5px;
    &.isFunnel {
      display: flex;
    }
  }
}
