@use "variables" as variable;
@use "mixins" as mixin;

/* |\\\\\\ General |\\\\\\  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
  -ms-overflow-style: scrollbar;
}
body.modal-open {
  height: 100vh;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}
body.showBarPromotion {
  padding-bottom: 60px !important;
  position: relative;
}
body .bar-promotion > div {
  display: block !important;
}
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  /*text-align:left;*/
}
body {
  font-family: variable.$primaryFont;
  background: #ffffff;
  overflow-x: hidden;
  padding: 0 !important;
}
a {
  background-color: transparent;
  text-decoration: none;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.3em;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.outline-none {
  outline: none;
}
.lk-wrap {
  width: 100%;
  max-width: 985px;
  margin: 0 auto;
  position: relative;
  &.large {
    max-width: 1200px;
  }
  &.xlarge {
    max-width: 1290px;
  }
}
.lk-grid {
  display: grid;
  &.twoCol {
    grid-template-columns: repeat(2, auto);
  }
  &.space-between {
    justify-content: space-between;
  }
}
.hide {
  display: none;
}
.lk-flex {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-align-items: -moz-stretch;
  -webkit-align-items: -webkit-stretch;
  -ms-align-items: -ms-stretch;
  align-items: stretch;
  text-align: center;
  width: 100%;
  padding: 0;
}
.lk-flex.space-between {
  justify-content: space-between;
}
.lk-flex.space-center {
  justify-content: center;
}
.lk-flex.align-start {
  align-items: start;
}
.lk-flex.align-items {
  align-items: center;
}
.lk-flex.flex-wrap,
.lk-flex.lk-wrap {
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-4 {
  padding-top: 40px;
}
.ml-2 {
  margin-left: 20px;
}
.w-80 {
  min-width: 80px;
}
.h-80 {
  min-height: 80px;
}
.full-width {
  width: 100% !important;
}
.no-margin {
  margin: 0 !important;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.text-center {
  text-align: center;
}
.page-contenu {
  background: #f8f5f2;
}
input.input-text,
.datepicker .custom-input {
  background-color: variable.$whiteColor;
  border: 1px solid variable.$primaryColor;
  border-radius: 5px;
  box-shadow: 0 3px 4px -3px #c3c3c2 inset;
  color: variable.$primaryColor;
  display: block;
  font-family: variable.$primaryFont;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  margin-bottom: 15px;
  text-indent: 5px;
  width: 100%;
  outline: 0 none;
  max-width: 100%;
}
.react-datepicker__input-container .custom-icon {
  right: 15px;
  top: 50%;
  width: 27px;
  height: 27px;
  transform: translateY(calc(-50% - 10px));
}
.react-datepicker__input-container .custom-icon + input {
  text-align: left;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 768px) {
  input.input-text,
  .datepicker .custom-input,
  .react-tel-input .form-control {
    font-size: 16px !important;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 768px) {
  input.input-text,
  .datepicker .custom-input,
  .react-tel-input .form-control {
    font-size: 16px !important;
  }
}
input.input-text:-webkit-autofill,
input.input-text:-webkit-autofill:hover,
input.input-text:-webkit-autofill:focus,
input.input-text:-webkit-autofill:active {
  color: variable.$primaryColor !important;
  caret-color: variable.$primaryColor !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: variable.$primaryColor;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px variable.$whiteColor;
  border-color: variable.$primaryColor;
}
input.input-text {
  caret-color: variable.$primaryColor !important;
}
.datepicker .custom-input {
  padding-top: 0;
  text-align: left;
}
input.input-text[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.input-text::placeholder {
  color: rgba(90, 0, 140, 0.45);
  opacity: 1;
}
input.input-text ::-ms-input-placeholder {
  color: rgba(90, 0, 140, 0.45);
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
button {
  outline: none;
}
button:hover {
  background-color: variable.$buttonColorHover;
}
input.input-text::-webkit-outer-spin-button,
input.input-text::-webkit-inner-spin-button {
  //-webkit-appearance: none;
  margin: 0;
}
input.input-text[type="number"] {
  //-moz-appearance: textfield;
  margin: 0;
}
.super-main {
  //min-height: calc(100vh - 260px);
  min-height: 400px;
}
.erreur-field,
.alert-text {
  font-family: variable.$primaryFont;
  color: #ff000d;
  font-weight: bold;
  font-size: 17px;
}
.required-field::after {
  content: "*";
  color: variable.$alertColor;
  margin-left: 3px;
  display: inline;
}
.erreur-field {
  font-size: 12px;
  display: block;
  position: relative;
  margin-top: -10px;
  margin-bottom: 10px;
}
.erreur-field.inLeft {
  text-align: left;
}
.erreur-field::before {
  display: inline;
  content: "⚠ ";
}
.erreur-field.atp {
  margin-top: 0;
}
.splayer::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 35px;
  top: 25px;
  height: 2px;
  background-color: #bbb;
  z-index: 9;
}
.splayer::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 35px;
  top: 25px;
  height: 2px;
  background-color: #bbb;
  z-index: 9;
}
.input-phone {
  text-align: left;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}
.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}
.error-color {
  color: variable.$alertColor;
}
.success-color {
  color: variable.$primaryColor;
}
.text-left {
  text-align: left;
}
.text-medium-size {
  font-size: variable.$mediumSize;
}
.section-footer {
  order: 20;
}
.page-cms {
  color: variable.$primaryColor;
}
#recaptcha_message\ {
  text-align: left;
  color: variable.$alertColor;
}
#easiformarea {
  font-family: variable.$primaryFont;
  text-align: center;
  font-size: 14px;
  font-style: normal;
}
#mandatoryfieldmention {
  text-align: right;
  font-size: 10px;
  font-style: italic;
  margin-bottom: 15px;
}
.lk-blc-title-s1 {
  font-family: variable.$CaveatBrush;
  color: variable.$primaryColor;
  font-size: variable.$size36;
  margin-bottom: 20px;
  text-align: center;
  &.caveat {
    font-family: variable.$CaveatBrush;
    font-size: variable.$size48;
  }
  &.left {
    text-align: left;
    img {
      margin-left: 0;
    }
  }
  &.mb0 {
    margin-bottom: 0;
  }
  img {
    margin: 10px auto 0;
    width: 120px;
    height: auto;
    display: none;
  }
  strong {
    color: variable.$orangeColor;
  }
}
.slider-meals,
.slider-products,
.slider-avis {
  .slick-arrow {
    top: -35px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/static/images/icons/arrow-slider-left.png");
    background-size: cover;
    text-indent: -9999px;
    font-size: 0;
    right: 50px;
    left: auto;
    &.slick-next {
      background-image: url("/static/images/icons/arrow-slider-right.png");
      right: 10px;
    }
  }
}
.slider-meals {
  .slick-arrow {
    top: 100px;
    z-index: 2;
    &.slick-prev {
      left: 10px;
    }
  }
}
label[class*="diets_in"] {
  border: 0;
}
@mixin alignSliderItems {
  .slick-track {
    display: flex;
    > div {
      height: auto;
      > div {
        height: 100%;
      }
    }
  }
}
.review-details-slider {
  @include alignSliderItems();
}
.slider-avis-programs {
  .slider-avis {
    .slick-arrow {
      width: 40px;
      height: 40px;
      top: auto;
      bottom: -75px;
      &.slick-prev {
        background-image: url("/static/images/icons/arrow-slider-left-white.png");
        left: 50%;
        margin-left: -45px;
      }
      &.slick-next {
        background-image: url("/static/images/icons/arrow-slider-right-white.png");
        right: 50%;
        margin-right: -45px;
      }
    }
  }
  @include alignSliderItems();
}
.slider-products {
  .slick-arrow {
    z-index: 9;
    top: 0;
    left: 10px;
    transform: none;
    width: 40px;
    height: 40px;
    &.slick-next {
      right: 10px;
      left: auto;
    }
  }
  span {
    visibility: hidden;
  }
  .nutriscore {
    display: none;
  }
  .slick-current {
    span {
      visibility: visible;
    }
    img {
      transform: scale(1.1);
    }
    .nutriscore {
      display: block;
      img {
        transform: none;
      }
    }
  }
}
.page-cms.homepage {
  display: flex;
  flex-direction: column;
}
.slider-reviews .slider-reviews-rtl {
  transform: scale(-1, 1);
}
.slider-reviews .slider-reviews-rtl .slick-slide {
  transform: scale(-1, 1);
}
.slider-reviews .slick-list {
  padding: 5px 0;
}
.slider-reviews {
  @include alignSliderItems();
}
.db-notification .Toastify__progress-bar--success {
  background-color: variable.$primaryColor;
}
.db-notification .Toastify__toast--success .Toastify__toast-icon path {
  fill: variable.$primaryColor;
}

body .field-select input {
  border: none !important;
}
.shippingType {
  display: none;
}
div.body main {
  max-width: 2200px;
  margin: 0 auto;
}
.page-stab {
  display: flex;
  flex-direction: column;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.only_mobile {
  display: none;
}
.lk-gourmandises-repas .listing {
  padding-top: 0;
  box-shadow: none;
}
.page-homme {
  .first {
    background: linear-gradient(
      180deg,
      rgb(198 168 228 / 70%) 0%,
      rgba(255, 255, 255, 0.7) 100%
    );
  }
}
.shippingRelay {
  width: 100% !important;
  > div label,
  > div div {
    opacity: 0;
    pointer-events: none;
    height: 1px;
    margin-bottom: 0 !important;
  }
}
.shippingRelayBox {
  opacity: 0;
  pointer-events: none;
  height: 1px;
  margin-bottom: 0 !important;
}
.shippingRelayAction {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  label {
    width: 100%;
  }
  button {
    max-width: 100% !important;
    font-size: 14px !important;
    margin-top: 0 !important;
    flex: 1;
    &.point {
      background-color: transparent;
      border: 1px solid rgba(90, 0, 140, 0.7);
      color: variable.$primaryColor;
      font-size: 15px !important;
      height: 32px;
      line-height: 30px;
      font-family: variable.$primaryFont;
      text-align: left;
      text-transform: lowercase;
      text-indent: 5px;
      border-radius: 5px;
      letter-spacing: 0;
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        width: 25px;
        height: 100%;
        right: 0;
        top: 0;
        background-repeat: no-repeat;
        background-color: variable.$orangeColor;
        display: block;
        position: absolute;
        background-image: url("/static/images/icons/picto-localisation.png");
        background-position: center;
        background-size: 10px auto;
      }
      &::first-letter {
        text-transform: uppercase;
      }
      &:hover {
        background-color: transparent;
      }
    }
    &.update {
      width: 100px;
      margin-right: 0;
      line-height: 32px;
      height: 32px;
      flex: inherit;
      margin-left: 5px !important;
      border-radius: 5px;
    }
  }
}

@include mixin.mediaDesktopSize() {
  .page-homme {
    .head-program {
      background: linear-gradient(
        102deg,
        #c5a8e4 31.94%,
        rgba(168, 228, 220, 0) 173.93%
      );
    }
    .box-label {
      p {
        white-space: nowrap;
      }
    }
    .first {
      background: none;
      .box {
        align-items: flex-end;

        .left-column {
          .description {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .super-main {
    min-height: calc(100vh - 360px);
    display: flex;
    flex-direction: column;
    width: 100%;
    > div {
      flex: 1;
    }
  }
  .slider-meals {
    .slick-arrow {
      &.slick-prev {
        left: -15px;
      }
      &.slick-next {
        right: -15px;
      }
    }
  }
}

@include mixin.mediaTablet() {
  .lk-wrap-content {
    padding: 0 2%;
  }
  .body {
    &.isOpen {
      height: 100%;
      width: 100%;
      position: absolute;
      overflow: hidden;
      #lk-footer::after,
      .super-main::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 3;
      }
      #lk-footer,
      .super-main,
      #lk-header {
        transition: transform 0.1s ease-in-out;
        transform: translateX(70%);
      }
      .super-main {
        height: calc(100vh - 57px);
      }
    }
  }
}
@include mixin.mediaSmallTab() {
  .lk-flex-smaltab-colomn {
    flex-direction: column;
  }
  .lk-blc-title-s1 {
    font-size: 9vw;
    line-height: 9.1vw;
    &.caveat {
      font-size: 9vw;
      line-height: 9vw;
    }
  }
  .slider-avis {
    .slick-arrow {
      top: auto;
      bottom: -40px;
      left: 50%;
      margin-left: -40px;
      &.slick-next {
        margin-left: 10px;
      }
    }
  }
}
@include mixin.mediaMobile() {
  .hide-mobile {
    display: none !important;
  }
  .splayer::after,
  .splayer::before {
    display: none;
  }
  .lk-flex-mob-colomn {
    flex-direction: column;
  }
  .only_tab_pc {
    display: none !important;
  }
  .only_mobile {
    display: block;
  }
}
@include mixin.mediaSmallMobile() {
  html,
  body {
    min-width: 320px;
  }
}
@include mixin.mediaTabletSize() {
  .slider-products .slick-arrow.slick-prev {
    left: 20%;
  }
  .slider-products .slick-arrow.slick-next {
    right: 20%;
  }
  .slider-products .slick-current img {
    transform: scale(1.25);
  }
}
