@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.calendar {
  display: flex;
  align-items: center;
  justify-content: left;
  span {
    font-family: variable.$titlesFont;
    font-size: variable.$size22;
    color: variable.$whiteColor;
    margin-left: 5px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}
