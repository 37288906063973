@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.cart {
  display: none;
  &.connected {
    display: block;
    a {
      text-indent: -9999px;
      font-size: 0;
      display: block;
    }
  }
}

@include mixin.mediaSmallTabletSize() {
  .cart {
    display: block;
    margin-left: 15px;
    a {
      color: variable.$blackColor;
      font-family: variable.$primaryFontSemiBold;
      font-size: variable.$size12;
      line-height: 24px;
      padding-left: 26px;
      position: relative;
      display: block;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: auto;
      }
    }
    &.connected {
      a {
        text-indent: inherit;
        font-size: variable.$size12;
      }
    }
  }
}
