@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.mobileMenu {
  background-color: variable.$whiteColor;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  transition: transform 0.1s ease-in;
  z-index: 6;
  .logo {
    padding: 10px;
    img {
      margin: 0 auto;
    }
  }
  li {
    display: block;
    padding-left: 9%;
    a {
      display: block;
      color: variable.$primaryColor;
      font-size: variable.$size16;
      line-height: 45px;
      font-family: variable.$primaryFontHeavy;
      img {
        display: inline-block;
        max-width: 25px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    &:last-child {
      a {
        border: 0;
      }
    }
    &.number {
      background-color: variable.$primaryColor;
      display: flex;
      align-items: flex-start;
      padding-top: 12px;
      padding-bottom: 12px;
      a {
        font-size: variable.$size22;
        color: variable.$whiteColor;
        line-height: 17px;
        border: 0;
        position: relative;
        padding-left: 50px;
        font-family: variable.$primaryFontBold;
        span {
          display: block;
          text-transform: uppercase;
          font-size: 8px;
          line-height: 12px;
          text-align: center;
        }
        img {
          max-width: 30px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .fixeLink {
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    a {
      max-width: 180px;
      margin: 0 auto;
    }
  }
}
.menuDesktop {
  display: none;
}

@include mixin.mediaSmallTabletSize() {
  .mobileMenu {
    li {
      &.number {
        a {
          &.compteMobile {
            display: none;
          }
        }
      }
    }
  }
}

@include mixin.mediaDesktopSize() {
  .mobileMenu {
    display: none;
  }
  .menuDesktop {
    display: block;
    ul {
      li {
        display: inline-block;
        padding: 0 8px;
        margin-right: 5px;
        &:first-child {
          padding-left: 0;
        }
        a {
          display: block;
          color: variable.$blackColor;
          font-size: variable.$size15;
          line-height: 24px;
          font-family: variable.$primaryFontHeavy;
          position: relative;
        }
        &:nth-child(2) {
          &:hover {
            > div {
              display: block;
            }
          }
        }
      }
    }
  }
}
