@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.boxInfo {
  padding: 18px 22px;
  background-color: variable.$whiteColor;
  &.newProd {
    .modalContent {
      .col {
        &:first-child {
          &::before {
            content: attr(data-new-label);
            border: 2px solid variable.$orangeColor;
            background-color: variable.$whiteColor;
            color: variable.$orangeColor;
            border-radius: 50%;
            padding-top: 30px;
            font-family: variable.$primaryFontBold;
            font-size: variable.$size11;
            left: 20px;
            top: 20px;
            background-image: url("/static/images/icons/hat.svg");
            background-size: 22px auto;
            background-position: top 7px center;
            width: 60px;
            height: 60px;
            background-repeat: no-repeat;
            text-align: center;
            position: absolute;
          }
        }
      }
    }
  }
  .nutriscore {
    > div {
      left: 0;
      right: auto;
      bottom: 15px;
      z-index: 1;
      width: 100%;
      img {
        width: 54px;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.modalContent {
  .row {
    display: flex;
    .col {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:first-child {
        background-color: variable.$bgColor;
      }
      .wrap {
        padding: 25px;
        width: 100%;
        box-sizing: border-box;
      }
      .thumbnail {
        padding: 25px;
        img {
          margin: 0 auto;
        }
      }
      .PSupplier {
        font-family: variable.$primaryFont;
        color: variable.$primaryColor;
        font-size: 12px;
        line-height: 32px;
        text-transform: uppercase;
        text-align: center;
      }
      .SDesc {
        color: variable.$primaryColor;
      }
      .name {
        text-align: center;
        font-family: variable.$titlesFont;
        font-weight: normal;
        color: variable.$primaryColor;
        letter-spacing: 1px;
        margin-bottom: 25px;
        position: relative;
        font-size: variable.$bigSize;
        line-height: variable.$size36;
        padding-bottom: 0;
      }
      .PContent {
        padding: 10px 0;
        border-top: 2px solid #eee5f3;
        border-bottom: 2px solid #eee5f3;
        margin-bottom: 20px;
        .PContentTab {
          display: table;
          margin: 0 auto;
          .rowTab {
            display: flex;
            flex-wrap: wrap;
            .cellTab {
              vertical-align: top;
              width: 100px;
              border-left: 2px dotted #b89cce;
              &:first-child {
                border-left: none;
              }
              .cellTabFit {
                height: 60px;
                text-align: center;
                display: flex;
                align-items: center;
                width: 100%;
                img {
                  max-width: 85%;
                  max-height: 100%;
                  margin: 0 auto;
                }
              }
              .cellTabTxt {
                text-align: center;
                line-height: 10px;
                span {
                  font-family: variable.$primaryFontBold;
                  color: #5a1a8c;
                  font-size: 9px;
                  line-height: 20px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
      .SDesc {
        p {
          font-family: variable.$primaryFont;
          color: variable.$primaryColor;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          margin: 0;
          &.FComplement {
            padding-top: 10px;
            text-transform: uppercase;
            font-size: 11px;
          }
        }
        .PPacking {
          display: block;
          line-height: 40px;
          text-transform: uppercase;
          font-size: 11px;
        }
      }
    }
  }
  .PTabs {
    padding-top: 25px;
    .nutritional {
      color: variable.$textColor;
      tr {
        td {
          padding: 5px;
        }
        &:nth-child(even) {
          background-color: #e4d1ee;
        }
      }
    }
    .PHeaderTabs {
      ul {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #e4d1ee;
        border-radius: 10px;
        padding: 5px;
        li {
          margin: 0 10px;
          width: 29%;
          display: block;
          text-align: center;
          button {
            text-align: center;
            display: block;
            padding: 6px 0;
            color: variable.$primaryColor;
            font-size: variable.$size16;
            line-height: variable.$bigSize;
            font-family: variable.$titlesFont;
            text-transform: none;
            background-color: transparent;
            box-shadow: none;
            letter-spacing: 0;
            border-radius: 5px;
          }
          &.active {
            button {
              background-color: variable.$primaryColor;
              color: variable.$whiteColor;
            }
          }
          &:last-child {
            button {
              line-height: variable.$size14;
            }
          }
        }
      }
    }
    .PBodyTabs {
      padding: 20px 0;
      min-height: 350px;
      .PTab {
        display: none;
        &.active {
          display: block;
        }
        table {
          max-width: 100%;
          width: 100% !important;
        }
        img {
          display: block;
          max-width: 100%;
          margin: auto;
        }
        li,
        p {
          font-family: variable.$primaryFont;
          color: variable.$primaryColor;
          font-size: 15px;
          text-align: left;
          line-height: 19px;
        }
        span {
          color: variable.$primaryColor;
          font-size: variable.$size15;
        }
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .modalContent {
    .row {
      flex-direction: column;
      .col {
        width: 100%;
        .thumbnail {
          img {
            width: 100%;
            max-width: 360px;
            margin: 0 auto;
          }
        }
        .wrap {
          padding: 0;
        }
      }
    }
  }
}

@include mixin.mediaMobile() {
  .boxInfo {
    padding: 0;
  }
  .modalContent {
    .row {
      .col {
        &:first-child {
          background-color: transparent;
        }
        &:last-child {
          padding: 20px 15px;
          background: variable.$bgColor;
          box-shadow: 2px 2px 5px 2px rgba(92, 45, 136, 0.25);
        }
        .thumbnail {
          padding: 55px 25px;
        }
        .name {
          font-size: 26px;
          line-height: 28px;
        }
      }
    }
    .PTabs {
      .PHeaderTabs {
        ul {
          flex-wrap: wrap;
          li {
            width: 33%;
            margin: 0;
          }
        }
      }
      .PBodyTabs {
        padding: 20px 10px;
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .boxInfo {
    padding: 15px 10px;
  }
  .modalContent {
    .row {
      .col {
        .name {
          font-size: 24px;
          line-height: 26px;
        }
      }
    }
    .PTabs {
      .PHeaderTabs {
        ul {
          li {
            button {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .PBodyTabs {
        padding: 20px 10px;
      }
    }
  }
}
