@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.button {
  background-color: transparent;
  box-shadow: none;
  color: variable.$primaryColor;
  font-size: variable.$size12;
  line-height: 24px;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
  &:hover {
    background-color: transparent;
  }
  img {
    width: 30px;
  }
}
.modal {
  .filterModal {
    width: calc(100% - 30px);
    min-width: auto;
    > div {
      border-radius: 20px;
      background-color: variable.$yellowLightColor !important;
    }
    .wrapper {
      padding: 40px 20px;
      .title {
        font-size: variable.$size26;
      }
      .action {
        max-width: max-content;
        margin: auto;
        button {
          padding: 0 20px;
        }
      }
      .message {
        color: variable.$primaryColor;
      }
      .field {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-block: 50px;
        flex-wrap: wrap;
        > span {
          width: 100%;
          text-align: center;
        }
        > div {
          flex: 1;
          max-width: 400px;
        }
        label {
          color: variable.$primaryColor;
        }
        input {
          margin: 0 !important;
          height: 45px;
          width: 100%;
        }
      }
    }
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

@include mixin.mediaTabletSize() {
  .filterModal {
    max-width: 768px;
    > div {
      border-radius: 20px;
    }
  }
}
