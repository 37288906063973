@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  a {
    max-width: 300px;
  }
  &:nth-child(2) {
    a {
      margin-top: 15px;
    }
  }
}
