@use "@styles/mixins" as mixin;

.loginModal {
  width: 810px;
}
.productModal {
  width: calc(100% - 30px);
  max-width: 1156px;
  border: 0 !important;
  transform: translateX(-50%) !important;
  top: 0 !important;
  > button {
    width: 25px !important;
    height: 25px !important;
    background-size: cover;
  }
}

@include mixin.mediaTabletSize() {
  .productModal {
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
  }
}
@include mixin.mediaTablet() {
  .loginModal {
    width: 610px;
    min-width: 300px !important;
  }
  .productModal {
    min-width: 0 !important;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 0 10px;
    background-color: transparent !important;
    > div {
      background: #ffffff;
    }
    > button {
      right: 0 !important;
    }
  }
}
@include mixin.mediaMobile() {
  .loginModal {
    width: 90%;
  }
  .productModal {
    padding: 0 10px;
  }
}
@include mixin.mediaSmallMobile() {
  .productModal {
    padding: 0 5px;
    max-width: 96%;
    button {
      right: -5px !important;
    }
  }
}
