@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.hamburger {
  padding: 10px;
  a {
    display: block;
    width: 30px;
    font-size: 0;
    text-indent: -99999px;
    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: variable.$blackColor;
      margin: 7px 0;
      border-radius: 2px;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include mixin.mediaDesktopSize() {
  .hamburger {
    display: none;
  }
}
