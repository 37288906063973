@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  p {
    margin-bottom: 15px;
  }
  strong {
    font-family: variable.$primaryFontBold;
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  ul {
    padding-left: 12px;
    li {
      list-style: disc;
    }
  }
}
