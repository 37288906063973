@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.subtabMenu {
  display: none;
}

@include mixin.mediaDesktopSize() {
  .subtabMenu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 35px;
    z-index: 999;
    padding-top: 25px;
    > div {
      > div {
        max-width: 750px;
        ul {
          max-width: 280px;
          border: 2px solid variable.$primaryColor;
          background-color: variable.$whiteColor;
          border-top: 0;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
          padding: 2px;
          margin-left: 17%;
          li {
            display: block;
            padding: 0;
            text-align: left;
            margin-right: 0;
            a {
              font-family: variable.$primaryFontHeavy;
              color: variable.$primaryColor;
              font-size: variable.$size14;
              line-height: 20px;
              padding: 10px;
              border-radius: 4px;
              overflow: hidden;
              img {
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
                &.hover {
                  display: none;
                }
              }
              &:hover {
                background-color: variable.$primaryColor;
                color: variable.$whiteColor;
                img {
                  display: none;
                  &.hover {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
