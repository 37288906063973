.kr-input-field {
  color: #000000 !important;
}

.kr-embedded {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kr-fields-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.kr-fields-container > div {
  width: 100%;
}
#paymentForm {
  margin-top: 20px;
}
.kr-payment-button {
  margin-top: 37px;
  background-color: #5a008c;
  border-radius: 40px;
  cursor: pointer;
  font-size: 20px !important;
  width: 300px !important;
  padding: 10px !important;
  font-family: "SFProDisplay-Bold";
  box-shadow: 2px 2px 6px 1px #ccc;
  border: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: #fff;
}
.kr-payment-button:hover {
  background-color: rgb(90, 0, 140, 0.7);
}
.kr-pan {
  flex: 3;
}
.kr-expiry {
  margin-left: 20px;
  flex: 1;
}
.kr-security-code {
  margin-left: 20px;
  flex: 1;
}

.kr-field-element {
  border-radius: 5px;
  border: 1px solid rgba(90, 0, 140, 0.7);
  background-color: #fff;
  margin: 7px 0;
  overflow: hidden;
  padding: 8px;
  width: 100%;
  text-align: left;
}

#account-update-expiry {
  margin: 0 !important;
}
#account-update-security-code {
  margin: 0 !important;
}
.kr-embedded .kr-payment-button:disabled {
  cursor: wait !important;
}

.kr-embedded .kr-form-error {
  order: 0 !important;
  margin-bottom: 15px;
}
