@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  label {
    color: variable.$primaryColor;
    font-family: variable.$primaryFontBold;
    cursor: pointer;
    margin-bottom: 0 !important;
    &.disabled {
      cursor: not-allowed;
      color: "#7B6798";
    }
  }
  input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 1.5em;
    height: 1.5em;
    border: 3px solid variable.$primaryColor;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: #fff;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      line-height: 0;
      font-size: 0;
      margin: 3px;
    }
    &:checked {
      &::after {
        background-color: variable.$primaryColor;
      }
      &:hover {
        background-color: "#ffffff";
        border: 3px solid variable.$primaryColor;
        ::after {
          background-color: variable.$primaryColor;
        }
      }
    }
    &:hover {
      &::after {
        background-color: "#7B6798";
      }
    }
    &:disabled {
      cursor: not-allowed;
      border: 3px solid "#7B6798";
      background-color: variable.$primaryColor;
      &:hover {
        &::after {
          background-color: variable.$primaryColor;
        }
      }
      &:checked {
        &::after {
          background-color: "#7B6798";
        }
        &:hover {
          background-color: variable.$primaryColor;
          &::after {
            background-color: "#7B6798";
          }
        }
      }
    }
  }
}
