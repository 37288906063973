@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  @include mixin.buttonPrimary;
}
.default {
  @include mixin.buttonPrimary;
  background-color: variable.$primaryColor;
  border-radius: 45px;
  &:disabled {
    background-color: rgba($color: variable.$primaryColor, $alpha: 0.5);
  }
  &:hover {
    background-color: rgba($color: variable.$primaryColor, $alpha: 0.7);
  }
  &.outline {
    background-color: transparent;
    border: 1px solid variable.$primaryColor;
    color: variable.$primaryColor;
    &:hover {
      background-color: variable.$primaryColor;
      color: variable.$whiteColor;
    }
  }
}
.white {
  @include mixin.buttonPrimary;
  background-color: variable.$whiteColor;
  color: variable.$primaryColor;
  border-radius: 45px;
  border: 1px solid variable.$whiteColor;
  &:hover {
    background-color: transparent;
    color: variable.$whiteColor;
  }
  &.outline {
    background-color: transparent;
    border: 1px solid variable.$whiteColor;
    color: variable.$whiteColor;
    &:hover {
      background-color: variable.$whiteColor;
      color: variable.$primaryColor;
    }
  }
}
.danger {
  @include mixin.buttonPrimary;
  background-color: variable.$orangeColor;
  color: variable.$whiteColor;
  border-radius: 45px;
  border: 1px solid variable.$orangeColor;
  &:hover {
    background-color: transparent;
    color: variable.$orangeColor;
  }
  &.outline {
    background-color: transparent;
    border: 1px solid variable.$orangeColor;
    color: variable.$orangeColor;
    &:hover {
      background-color: variable.$orangeColor;
      color: variable.$whiteColor;
    }
  }
}
.secondary {
  @include mixin.buttonSecondary;
  border-radius: 40px;
  &.outline {
    background-color: transparent;
    color: variable.$secondaryColor;
    &:hover {
      background-color: variable.$secondaryColor;
      color: variable.$whiteColor;
    }
  }
}

.secondaryGreen {
  @include mixin.buttonSecondaryGreen;
}
.secondaryWhite {
  @include mixin.buttonSecondary;
}
.thin {
  font-family: variable.$primaryFont;
  border: none;
  box-shadow: 2px 2px 5px 2px rgba($color: variable.$primaryColor, $alpha: 0.25);
  &:disabled {
    opacity: 1;
    background-color: #d6bfe2;
  }
}
.primaryLight {
  background-color: variable.$whiteColor;
  color: variable.$secondaryColor;
  font-family: variable.$Freeman;
  border: 1px solid variable.$secondaryColor;
  &:hover {
    background-color: variable.$secondaryColor;
    color: variable.$whiteColor;
  }
}
.secondaryGreenLight {
  background-color: variable.$greenLightColor;
  color: variable.$primaryColor;
  font-family: variable.$primaryFontBold;
  &:hover {
    background-color: variable.$primaryColor;
    color: variable.$whiteColor;
  }
}
