@use "@styles/variables" as variable;

.root {
  width: 100%;
  max-width: 419px;
  margin: 0 auto 50px;
  fieldset {
    border: none;
    display: block;
    legend {
      margin-bottom: 45px !important;
    }
    > div {
      padding: 0.5rem 0;
      display: grid;
      gap: 1rem;
      position: relative;
      span {
        color: #545454;
        font-size: 13px;
        font-family: variable.$primaryFont;
        cursor: pointer;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -1px;
        width: 3px;
        height: 10px;
        background-color: variable.$primaryColor;
        top: 10px;
        z-index: 2;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: -1px;
        width: 3px;
        height: 10px;
        background-color: variable.$primaryColor;
        top: 10px;
        z-index: 2;
      }
      .startLabel {
        position: absolute;
        top: -20px;
        color: variable.$primaryColor;
        font-size: variable.$size14;
        line-height: 20px;
        font-family: variable.$primaryFont;
        border-radius: 5px;
        background-color: #fff;
        padding: 1px 5px;
        box-shadow: 5px 4px 30px 0px rgba(0, 0, 0, 0.15);
        &::after {
          content: "";
          display: block;
          background-image: url("/static/images/icons/polygon.png");
          width: 10px;
          height: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px auto;
          position: absolute;
          left: 0;
          width: 100%;
          bottom: -8px;
        }
      }
      .endLabel {
        position: absolute;
        top: -20px;
        right: 0;
        color: variable.$primaryColor;
        font-size: variable.$size14;
        line-height: 20px;
        font-family: variable.$primaryFont;
        border-radius: 5px;
        background-color: #fff;
        padding: 1px 5px;
        box-shadow: 5px 4px 30px 0px rgba(0, 0, 0, 0.15);
        &::after {
          content: "";
          display: block;
          background-image: url("/static/images/icons/polygon.png");
          width: 10px;
          height: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px auto;
          position: absolute;
          left: 0;
          width: 100%;
          bottom: -8px;
        }
      }
    }
  }
}
